import React from 'react';
import Modal from "react-bootstrap/Modal";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import CrsForm from './CrsForm';
import { APPLICATION_CRS, CRS_BUY_LINK } from '../../../../config/Constants';
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense';



export class CrsSubjectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            title:"Nuovo Soggetto"
        }
    }

    closeModal = () => {
        this.setState({ showModal: false });
    };

    openModal = () => {
        const subjectSummary = this.props.subjectSummary;
        if (subjectSummary && subjectSummary?.available > 0) {
            const axiosInstance = axiosService.getInstance();
            axiosInstance.get('/api/crs/custom/crs-subjects/lastProgressive')
                .then(res => {
                    let newCode = parseInt(res.data) + 1
                    this.setState({ newSubjectCode: newCode, showModal: true })
                });
        } else {
            PopupSubjectLicense({ total: subjectSummary?.total
                , used: subjectSummary?.used
                ,available:subjectSummary?.available
                ,name:APPLICATION_CRS });
        }

    };

    render() {
        let disabled = this.props.licenseExpired;
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary  btn-new-rel  btn-sm" disabled={disabled}
                    onClick={this.openModal}><IPlus className={"padding-bottom-4px"} width="14" fill={` ${disabled ? '#128186' : '#FFFFFF'}`} />&nbsp; NUOVO SOGGETTO
                </button>
                <Modal
                    backdrop="static"
                    id={"subject-modal"}
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    dialogClassName="modal-subject"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>{this.state.title}</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CrsForm {...this.props}
                            disableCreateCommunication={disabled}
                            showModal={this.state.showModal}
                            newSubjectCode={this.state.newSubjectCode}
                            handleCloseModal={this.closeModal} />
                    </Modal.Body>
                </Modal>


            </React.Fragment>
        )
    }
}
