import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import { BtnFill } from "../../../shared/BtnFill";
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import { CoraSubjectForm } from "./CoraSubjectForm";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as IPlus } from "../../../../styles/images/svgs/regular/plus.svg";
import * as Constants from "../../../../config/Constants";
import { isEmpty, isNotEmpty } from "../../../shared/Utility";
import { saveSid, validateSid } from '../../../shared/SidCertsHandler';
import { PopupSubjectLicense } from '../../../shared/PopupSubjectLicense';
export class CoraSubjectModal extends React.Component {

    constructor() {
        super();
        this.state = {
            checked: false,
            subjectSaved: false,
            subject: {},
            personType: Constants.PNF,
            fiscalCode: '',
            companyName: '',
            location: '',
            province: '',
            coraSubjectSid: {
                id: null,
                certificate1: '',
                certificate2: '',
                certificate3: '',
                password: '',
                email: '',
                sidNumber: '',
                flagPec: false,
                expirationDate: null
            },
            errorFiscalCode: { isValid: true, msg: "" },
            errorCompanyName: { isValid: true, msg: "" },
            errorLocation: { isValid: true, msg: "" },
            errorProvince: { isValid: true, msg: "" },
            certificateFiles: [],
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: '',
            isCertSidOpened: false,
            showModal: false,
        }
    }


    updatePecConfiguration = (coraSubjectSid) => {
        this.setState({ ...this.state, coraSubjectSid: coraSubjectSid });
    }


    getCurrentUser = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance.get(`/api/common/users/current`)
            .then(res => {
                if (isNotEmpty(res)) {
                    this.setState({
                        coraSubjectSid: {
                            email: res.data.email
                        }
                    })
                }
            });
    };

    handleSelectLocation = (location) => {
        this.setState({
            location: location,
            errorLocation: { isValid: true, msg: "" }
        })
        if (isEmpty(location))
            this.setState({ province: '' })
        else
            actions.getLocation(location).then(
                (locOBJ) => {
                    if(locOBJ){
                        this.setState({
                            province: locOBJ.province,
                            errorProvince: { isValid: true, msg: "" }
                        })
                    }
                });
    };

    handleChange = (event) => {
        if (event) this.state.errorInSubject = '';
        if (event._dispatchInstances.memoizedProps.type === "companyName") {
            this.setState({
                companyName: event.target.value.toUpperCase(),
                errorCompanyName: { isValid: true, msg: "" }
            })
        }
        if (event._dispatchInstances.memoizedProps.type === "provincia") {
            this.setState({
                province: event.target.value.toUpperCase(),
                errorProvince: { isValid: true, msg: "" }
            })
        }
    };

    formValidation = () => {
        let isValid = true;
        if (this.state.personType === Constants.PNF) {
            if (this.state.companyName == '') {
                this.setState({ errorCompanyName: { isValid: false, msg: "Campo obbligatorio" } });
                isValid = false;
            }
        }
        if (this.state.location == '' || this.state.location == undefined) {
            this.setState({ errorLocation: { isValid: false, msg: "Campo obbligatorio" } });
            isValid = false;
        }
        if (this.state.province == '' || this.state.province == undefined) {
            this.setState({ errorProvince: { isValid: false, msg: "Campo obbligatorio" } });
            isValid = false;
        }

        /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
        if (!this.state.isCertSidOpened) return isValid;

        if (!this.configurationSidValidation()) {
            isValid = false;
        }
        return isValid;
    };

    addCoraSubject = (sid) => {
        let newSubject = this.state.subject;
        newSubject.fiscalCode = this.state.fiscalCode;
        newSubject.companyName = this.state.companyName;
        newSubject.personType = this.state.personType;
        newSubject.firstName = this.state.firstName;
        newSubject.lastName = this.state.lastName;
        newSubject.gender = this.state.gender;
        newSubject.birthDate = this.state.birthDate;
        newSubject.location = this.state.location;
        newSubject.province = this.state.province;
        newSubject.createDate = new Date();
        newSubject.modifyDate = new Date();
        newSubject.coraSubjectSid = sid;
        this.props.addCoraSubject(newSubject);
        this.closeModal();
    };

    handlePersonTypeClick = (event) => {
        const type = event.target.value;
        if (type === Constants.PF) {
            this.setState({ personType: event.target.value, gender: 'FEMALE', companyName: undefined })
        } else {
            this.setState({
                personType: event.target.value,
                gender: undefined,
                firstName: undefined,
                lastName: undefined,
                birthDate: undefined
            });
        }
    }

    handlerChangeGender = (e) => {
        this.setState({ gender: e.target.value })
    }

    handlerChangeBirthDate = (date) => {
        this.setState({ birthDate: new Date(date) })
    }

    handlerChangeFirstName = (e) => {
        this.setState({ firstName: e.target.value?.toUpperCase() })
    }

    handlerChangeLastName = (e) => {
        this.setState({ lastName: e.target.value?.toUpperCase() })
    }

    updateAll = () => {
        if (!this.formValidation()) return;

        saveSid(this.state.certificateFiles?.files
            , this.state.coraSubjectSid
            , this.state.fiscalCode
            , "cora"
            , (res) => this.addCoraSubject(res)
            , (errors) => {
                PopupError({ ...this.props, text: Constants.APPLICATION_GENERIC_ERROR });
                this.resetError();
                this.resetFields();
            })

    };

    configurationSidValidation = () => {

        return validateSid(
            this.state.coraSubjectSid.sidNumber
            , this.state.coraSubjectSid.email
            , (state) => this.setState(state)
            , this.state.errorSidNumber
            , this.state.errorEmail
            , "errorSidNumber"
            , "errorEmail"
        )

    };


    setStateFunction = (value) => {
        this.setState(value)
    }

    /* Validation onChange */
    handleChangeSid = (val, error, errorMsg) => {
        this.state.errorInConfigSid = '';
        let value = val.target.value;
        let name = val.target.name;
        if (name === "password") {
            this.state.coraSubjectSid.password = value.toUpperCase().trim();
        }
        if (name === "sidNumber") {
            if(this.checkSid(value.toUpperCase().trim())){
                this.setState({
                    coraSubjectSid: {
                        ...this.state.coraSubjectSid,
                        id: null,
                        sidNumber: value.toUpperCase().trim()
                    }
                })        
            } else {
                PopupError({text:`Numero SID: ${value}<br> Rilevato per più di un soggetto: registrazione impossibile.`});
            }
        }
        if (name === "email") {
            this.state.coraSubjectSid.email = value.toUpperCase().trim();
        }

        if (isEmpty(value)) {
            error.isValid = false;
            error.msg = errorMsg;
            this.setState(error);
        } else {
            error.isValid = true;
            error.msg = '';
            this.setState(error);
        }
    };

    checkSid(sidNumber){
        const allSubjects= this.props.subjects || [];
        for(const subject of allSubjects){
            const sid= subject.coraSubjectSid?.sidNumber;
            if(subject.id != this.state.subject.id && sid === sidNumber){
                PopupError({text:"Rilevato stesso numero SID su più soggetti CORA. Verificare che il numero SID sia associato ad un solo soggetto."});
                return false
            } 
        }
        return true;
    }

    handleChangeStartDate = (date) => {
        if (date) {
            this.state.coraSubjectSid.startDate = date;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.startDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeExpirationDate = (val) => {
        if (val) {
            this.state.coraSubjectSid.expirationDate = val;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.expirationDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeEncryptionBits = (val) => {
        this.state.coraSubjectSid.encryptionBits = val;
        this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
    }

    handlerChangeFiscalCode = (e) => {
        this.setState({ fiscalCode: e.target.value.toUpperCase().trim() });
    };

    handleFiscalCode = fiscalCode => {
        if(fiscalCode){
            actions.getBaseRegistryByFiscalCode(fiscalCode).then(
                (baseRegistry) => {
                    if (baseRegistry) {
                        this.setState({
                            companyName: baseRegistry?.companyName || '',
                            location: baseRegistry?.location?.location || '',
                            province: baseRegistry?.location?.province || '',
                            lastName: baseRegistry?.lastName || '',
                            firstName: baseRegistry?.firstName || '',
                            birthDate: new Date(baseRegistry?.birthDate) || null,
                            gender: baseRegistry?.gender
                        })
                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
            actions.getCoraSubjectSidByFiscalCode(fiscalCode, "cora").then(
                (coraSubjectSid) => {
                    if (coraSubjectSid?.id) {
                        this.setState({
                            coraSubjectSid: coraSubjectSid,
                            errorCertificates: { isValid: true, msg: "" },
                            errorPassword: { isValid: true, msg: "" },
                            errorSidNumber: { isValid: true, msg: "" },
                            errorEmail: { isValid: true, msg: "" }
                        });
                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
            
           
        }
    };

    setCertificateFilesCustom = (val) => {
        /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
        this.state.isCertSidOpened = true;

        if (isNotEmpty(val))
            this.state.certificateFiles = val
    };


    resetError = () => {
        this.setState({
            errorCompanyName: { isValid: true, msg: "" },
            errorLocation: { isValid: true, msg: "" },
            errorProvince: { isValid: true, msg: "" },
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: ''
        });
    };

    resetFields = () => {
        this.setState({
            companyName: this.state.companyName,
            location: this.state.location,
            province: this.state.province,
            coraSubjectSid: {}
        })
    };

    closeModal = () => {
        this.resetError();
        this.resetFields();
        this.setState({ showModal: false });
        this.setState({ fiscalCode: '' });
        this.setState({ companyName: '' });
        this.setState({ location: '' });
        this.setState({ province: '' });
    };

    openModal = () => {
        const subjectSummary = this.props.subjectSummary;
        if (subjectSummary && subjectSummary?.available > 0) {
            this.getCurrentUser();
            this.setState({ showModal: true });
        } else {
            PopupSubjectLicense({ total: subjectSummary?.total
                , used: subjectSummary?.used,available:subjectSummary?.available
                , name: Constants.APPLICATION_CORA });
        }
    };

    handlerChangeFlagPec = (e) => {
        let checked = e.target.checked
        this.setState(prevState => ({

            coraSubjectSid: {
                ...prevState.coraSubjectSid,
                flagPec: checked
            }
        }));
    }

    render() {
        let disabled = this.props.disabled;
        return (
            <React.Fragment>
                <button type="button" className="btn btn-new-rel px-5 btn-sm"
                    onClick={this.openModal} disabled={disabled}>
                    <IPlus className={"padding-bottom-4px"} width="14" fill={` ${disabled ? '#128186' : '#FFFFFF'}`} />&nbsp; NUOVO
                    SOGGETTO
                </button>
                <Modal
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    dialogClassName="width-90 p-2"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>Nuovo Soggetto</h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CoraSubjectForm {...this} key="coraSubjectNewModal" modal={true} />
                    </Modal.Body>
                    <Modal.Footer>
                        <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.closeModal} />
                        <BtnFill text="CONFERMA" classCustom="float-end text-end"
                            handlerOnClick={this.updateAll} />
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}
